<template>
  <v-container>
    <div class="text-right"><PeopleSort @update="fetchData" /></div>
    <PeoplePanel :value="schoolClass.students" />
  </v-container>
</template>
<script>
import { sortPeople } from "common/utils/people.js";
import { saveChildRoute } from "@/router/helper.js";
import PeoplePanel from "common/components/PeoplePanel.vue";
import PeopleSort from "@/components/settings/PeopleSort.vue";

export default {
  name: "SchoolClassPortrait",
  components: { PeoplePanel, PeopleSort },
  props: ["id"],
  data() {
    return {
      schoolClass: {},
      loading: false,
    };
  },
  methods: {
    async fetchData() {
      if (this.id > 0) {
        this.loading = true;
        this.schoolClass = await this.apiGet({
          resource: "schoolclass/schoolclass",
          id: this.id,
        });
        this.schoolClass.students = sortPeople(this.schoolClass.students);
        this.loading = false;
      }
    },
  },
  watch: {
    async id() {
      await this.fetchData();
    },
  },
  async created() {
    await this.fetchData();
  },
  beforeRouteUpdate(to, from, next) {
    saveChildRoute(to, next);
  },
};
</script>
